import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import 'swiper/swiper-bundle.css';
import './fonts/stylesheet.css';
import './tailwind.css';
import './styles/swiper/pagination.css';
import './styles/cookiebanner.css';

import 'alpine-magic-helpers/dist/scroll';
import 'alpine-magic-helpers/dist/fetch';
import 'alpine-magic-helpers/dist/interval';
import 'alpinejs';

import Gumshoe from 'gumshoejs/src/js/gumshoe/gumshoe';
import Swiper from 'swiper/bundle';
import initPhotoSwipeFromDOM from './js/photoswipe.js';
import CookiesEuBanner from 'cookies-eu-banner';


var banner = new CookiesEuBanner(function(){
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', i, { 'anonymize_ip': true });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtag/js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'UA-xxx-1');
});

window.fullscreenModal = function() {
  const parser = new DOMParser();
  return {
    parse(data) {
      this.open = true;
      const doc = parser.parseFromString(data, "text/html");
      const content = doc.querySelector(".text-page-container");
      this.$refs.container.innerHTML = content.innerHTML;
    },
    open: false
  }
}


document.addEventListener('DOMContentLoaded', () => {

  if (document.getElementsByClassName('gallery-main').length) {
    var galleryThumbs = {};

    if (document.getElementsByClassName('gallery-thumbs').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs', {
        spaceBetween: 20,
        slidesPerView: 4,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: false,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          // 768: {
          //   slidesPerView: 2,
          //   spaceBetween: 20,
          //   direction: 'vertical',
          // },
        }
      })};
    }

    var galleryTop = new Swiper('.gallery-main', {
      spaceBetween: 0,
      preloadImages: false,
      pagination: {
        el: '.gallery-main-indicator',
        type: 'fraction',
      },
      // Enable lazy loading
      lazy: true,
      thumbs: galleryThumbs
    });

    initPhotoSwipeFromDOM('.gallery-main');
  }

  if (document.getElementsByClassName('gallery-bottom').length) {
    var galleryThumbsVertical = {};

    if (document.getElementsByClassName('gallery-thumbs-vertical').length) {
      galleryThumbsVertical = {swiper: new Swiper('.gallery-thumbs-vertical', {
        spaceBetween: 20,
        slidesPerView: 4,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: false,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
            direction: 'vertical',
          },
        }
      })};
    }
    var galleryBottom = new Swiper('.gallery-bottom', {
      spaceBetween: 0,
      preloadImages: false,
      pagination: {
        el: '.gallery-main-indicator',
        type: 'fraction',
      },
      // Enable lazy loading
      lazy: true,
      thumbs: galleryThumbsVertical,
    });

    initPhotoSwipeFromDOM('.gallery-bottom');
  }


  //.home-hero-slide
  if (document.getElementsByClassName('home-hero-slide').length) {
    var HomeHeroSlide = new Swiper('.home-hero-slide', {
      spaceBetween: 10,
      slidesPerView: 1,
      preloadImages: true,
      pagination: {
        el: '.home-hero-indicator',
        clickable: true,
      },
      breakpoints: {
        // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
        // TODO: import from tailwind
        768: {
          slidesPerView: 2,
          spaceBetween: 10
        },
      },
      // Enable lazy loading
      lazy: true,
    });

    initPhotoSwipeFromDOM('.home-hero-slide');
  }

  var spy = new Gumshoe('#ScrollMenu a');

});
